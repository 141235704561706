@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: 'CircularStd-SemiBold';
    src: url("../src/assets/fonts/CircularStd-Bold.ttf")
}

@font-face {
    font-family: 'CircularStd-Bold';
    src: url("../src/assets/fonts/CircularStd-Black.ttf")
}

@font-face {
    font-family: 'CircularStd-Medium';
    src: url("../src/assets/fonts/CircularStd-Book.ttf")
}

@font-face {
    font-family: 'PublicSans-Bold';
    src: url("../src/assets/fonts/PublicSans-Bold.ttf")
}

@font-face {
    font-family: 'PublicSans-Medium';
    src: url("../src/assets/fonts/PublicSans-Medium.ttf")
}

@font-face {
    font-family: 'PublicSans-Regular';
    src: url("../src/assets/fonts/PublicSans-Regular.ttf")
}

@font-face {
    font-family: 'PublicSans-SemiBold';
    src: url("../src/assets/fonts/PublicSans-SemiBold.ttf")
}

.dashboardslide::-webkit-scrollbar {
    display: none;
}
  
.dashboardslide {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

img {
    max-width: 100%;
    display: block;
}

figure {
    margin: 0px;
    display: grid;
    grid-template-rows: 1fr auto;
    /* margin-bottom: 10px; */
    break-inside: avoid; 
}

figure > img {
    grid-row: 1 / -1;
    grid-column: 1;
    padding-top: 20px;
}

figure a {
    color: black;
    text-decoration: none;
}

figcaption {
    grid-row: 2;
    grid-column: 1;
    background-color: rgba(255,255,255,.5);
    padding: .2em .5em;
    justify-self: start;
}

.container {
    column-count: 4;
    width: 100%; 
  }
.containermobile {
    column-count: 2;
    width: 100%; 
}
